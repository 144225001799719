import React from 'react'

import './rodape.estilo.scss'

export default function Rodape() {
    return (
        <div className='conteinerRodateGeral'>
           <span>MNL - &copy;Movimento Nacional de Lutas</span>
           <span>Pederneiras/Bauru - SP</span>
           <span><a href='https://www.mundoslackware.com.br' target='blank'>Desenvolvido por Mundo Slackware Security</a>&reg;</span>
        </div>
    )
}