import React from 'react'
import './inicio.estilo.scss'

import Bandeira from '../imagens/manutencao.jpg'


export default function Manutencao(){
    return(
        <div className='conteinerInicioGeral'>
            <img src={Bandeira} alt='Bandeira MNL' />
        </div>
    )
}