import React from 'react'
import Rotas from './Router'
//import Manutencao from './Manutencao'
import AuthProvider from './Components/Contexts/AuthContext'
import { ToastContainer } from 'react-toastify'

export default function App() {
  return (
    <AuthProvider>
      <div>
        <Rotas />
        <ToastContainer
          autoClose={15000}
        />
      </div>
    </AuthProvider>
  )
}

