import React, { useState, useEffect } from 'react'
import apiLocal from '../apiLocal/apiLocal'
import './cadastroAcampados.estilo.scss'
import { useNavigate } from 'react-router-dom'
import { IMaskInput } from 'react-imask'
import { cpf } from 'cpf-cnpj-validator'
import { toast } from 'react-toastify'

export default function CadastroAcampados() {

    const navegar = useNavigate()

    const [aceite, setAceite] = useState(false)
    const [estadoCivil, setEstadoCivil] = useState([''])
    const [acampamentos, setAcampamentos] = useState([''])

    const [nomeMask, setNomeMask] = useState('')
    const [rgMask, setRgMask] = useState('')
    const [cpfMask, setCpfMask] = useState('')
    const [password, setPassword] = useState('')
    const [nisMask, setNisMask] = useState('')
    const [cadunicoMask, setCadUnicoMask] = useState('')
    const [tituloeleitorMask, setTituloEleitorMask] = useState('')
    const [celularMask, setCelularMask] = useState('')
    const [acampamentoId, setAcampamentoId] = useState('')
    const [estado_civilId, setEstadoCivilId] = useState('')

    useEffect(() => {
        try {
            async function verDadosEstadoCivil() {
                const respostaec = await apiLocal.get('/VerEstadoCivil')
                setEstadoCivil(respostaec.data)
            }
            verDadosEstadoCivil()
        } catch (error) {

        }
    }, [])


    useEffect(() => {
        try {
            async function verDadosAcampamentos() {
                const respostaA = await apiLocal.get('/VerAcampamentos')
                setAcampamentos(respostaA.data)
            }
            verDadosAcampamentos()
        } catch (error) {

        }
    }, [])

    async function cadastrarAcampado(e) {
        try {
            e.preventDefault()
            if (!nomeMask || !rgMask || !cpfMask || !password || !nisMask || !cadunicoMask || !tituloeleitorMask || !celularMask) {
                toast.warning('Existem Campos Em branco', {
                    toastId: 'ToastID'
                })
                return
            }

            const cpfValido = cpf.isValid(cpfMask)
            let regex = /^(?=(?:.*?[A-Z]){1})(?=(?:.*?[a-z]){1})(?=(?:.*?[0-9]){1})(?=(?:.*?[!@#$%*()_+^&}{:;?.]){1})(?!.*\s)[0-9a-zA-Z!@#$%;*(){}_+^&]*$/
            if (!cpfValido) {
                toast.error('CPF Inválido', {
                    toastId: 'ToastID'
                })
                return
            } else if (password.length < 6) {
                toast.error('A senha Contém menos de 6 caracteres', {
                    toastId: 'ToastID'
                })
                return
            } else if (!regex.exec(password)) {
                toast.warning('A senha deve conter no mínimo 1 caractere em maiúsculo, 1 caractere em minusculo, 1 número e 1 caractere especial!', {
                    toastId: 'ToastID'
                })
                return
            }

            let nome = nomeMask.toLocaleLowerCase()
            let rg = rgMask.match(/\d/g).join("")
            let cpfV = cpfMask.match(/\d/g).join("")
            let nis = nisMask.match(/\d/g).join("") 
            let cadunico = cadunicoMask.match(/\d/g).join("")
            let tituloeleitor = tituloeleitorMask.match(/\d/g).join("")
            let celular = celularMask.match(/\d/g).join("")

            const resposta = await apiLocal.post('/CriarAcampadosTitulares', {
                nome,
                rg,
                cpfV,
                password,
                nis,
                cadunico,
                tituloeleitor,
                celular,
                acampamentoId,
                estado_civilId
            })
            toast.success(resposta.data.dados)
            navegar('/LoginAcampados')

        } catch (err) {
            toast.error('Erro ao Cadastrar')
        }
    }

    function handleAceite() {
        setAceite(!aceite)
    }

    return (
        <div className='conteinerGeralCadastroAcampados'>
            <h1>Cadastro Acampados</h1><br />
            <p>
                <h2>
                    Para se cadastrar-se no Movimento Nacional de Lutas, que luta por reforma agrária e moradia social no Brasil envolve algumas etapas e requisitos
                    , aqui estão algumas das principais diretrizes gerais: <br /> <br />
                </h2>

                1.  Identificação: Apresentar documentos de identificação após cadastro, e aguardar aprovação. <br />

                2.  Motivação: Declarar seu interesse e compromisso com a causa da reforma agrária.<br />

                3.  Participação: Estar disposto a participar de atividades e reuniões do movimento, mostrando engajamento com a luta social.<br />

                4.  Formação: Alguns movimentos oferecem cursos ou oficinas sobre temas relacionados à reforma agrária, e a participação pode ser um requisito.<br />

                5.  Coletividade: Demonstrar interesse em trabalhar coletivamente, respeitando a diversidade e a democracia interna do movimento.<br />

                6.  Compromisso: Assinar um termo de compromisso que pode incluir a adesão aos princípios e objetivos do movimento.<br />

                7.  Atividades: Participar de atividades de base, como assembleias, protestos ou ações comunitárias.<br />
            </p><br />
            <p>
                Para continuar com o cadastro, precia aceitar as diretrizes selecione a caixa abaixo: <br />
                <input
                    type='checkbox'
                    onChange={handleAceite}
                    checked={aceite}
                />  Aceito as diretrizes
            </p>

            {aceite === true && (
                <form onSubmit={cadastrarAcampado}>
                    <input
                        type='text'
                        placeholder='Nome do Titular*'
                        value={nomeMask}
                        onChange={(e) => setNomeMask(e.target.value)}
                    />
                    <input
                        type='text'
                        placeholder='RG*'
                        value={rgMask}
                        onChange={(e) => setRgMask(e.target.value)}
                    />
                    <IMaskInput
                        type="text"
                        mask='000.000.000-00'
                        placeholder='CPF*'
                        value={cpfMask}
                        onChange={(e) => setCpfMask(e.target.value)}
                    />
                    <input
                        type='password'
                        placeholder='Senha Usada Para Logar*'
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <input
                        type='text'
                        placeholder='NIS*'
                        value={nisMask}
                        onChange={(e) => setNisMask(e.target.value)}
                    />
                    <input
                        type='text'
                        placeholder='Cadastro Único*'
                        value={cadunicoMask}
                        onChange={(e) => setCadUnicoMask(e.target.value)}
                    />
                    <input
                        type='text'
                        placeholder='Titulo de Eleitor*'
                        value={tituloeleitorMask}
                        onChange={(e) => setTituloEleitorMask(e.target.value)}
                    />
                    <IMaskInput
                        type='text'
                        mask='(00) 00000-0000'
                        placeholder='Celular(com WhatsApp)*'
                        value={celularMask}
                        onChange={(e) => setCelularMask(e.target.value)}
                    />
                    <select
                        value={estado_civilId}
                        onChange={(e) => setEstadoCivilId(e.target.value)}
                    >
                        <option value='0' >Selecione o Estado Civil*</option>
                        {estadoCivil.map((item) => {
                            return (
                                <option value={item.id}>
                                    {item.nome}
                                </option>
                            )
                        })}
                    </select>
                    <select
                        value={acampamentoId}
                        onChange={(e) => setAcampamentoId(e.target.value)}
                    >
                        <option value='0' >Selecione o Acampamento*</option>
                        {acampamentos.map((item) => {
                            return (
                                <option value={item.id}>
                                    {item.nome}
                                </option>
                            )
                        })}
                    </select>
                    <button type='submit'>Enviar</button>
                    <p>Campos com * são obrigatórios</p>
                    <p>Caso não tenha o Cad Unico inserir o numero do NIS no lugar<br />
                        A senha deve conter no minimo 6 caracteres <br />
                        A senha deve conter no mínimo 1 caractere em maiúsculo, 1 caractere em minusculo, 1 número e 1 caractere especial!</p>
                </form>
            )}
        </div>
    )
}