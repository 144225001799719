import React from 'react'
import { Link } from 'react-router-dom'

import Acampados from '../imagens/acampados.png'
import Coordenadores from '../imagens/coordenadores.png'

import './login.estilo.scss'


export default function Login() {
    return (
        <div className='conteinerLoginGeral'>
            <h1>Clique na Imagem para Logar</h1>
            <div className='conteinerAcessoLogins'>
                <Link to='/LoginAcampados'><img src={Acampados} alt='Acampados' /></Link>
                {/*<Link to='/LoginCoordenadores'> <img src={Coordenadores} alt='Coordenadores' /></Link>*/}
            </div>
        </div>
    )
}