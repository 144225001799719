import React, { useContext } from 'react'
import { AuthContexts } from '../Components/Contexts/AuthContext'

import './qSomos.estilo.scss'


export default function QSomos() {
    const { verificaToken } = useContext(AuthContexts)
    verificaToken()   
    return (
        <div className='conteinerGeralQsomos'>
            <h1>Quem Somos</h1>
            <p>
                O Movimento Nacional de Lutas (MNL) foi fundado no dia 22 de junho de 2021,
                com o objetivo de atuar como uma força social em prol da moradia digna e da reforma agrária no Brasil.
                Idealizado por Ricardo Terra, o movimento surge em um contexto de crescente desigualdade social e dificuldade
                de acesso a direitos fundamentais, como a terra e a habitação.
            </p>
            <p>
                A iniciativa do MNL foi impulsionada pela percepção de que as políticas públicas existentes eram insuficientes
                para atender à demanda por moradia social e redistribuição de terras produtivas. O movimento se organiza a 
                partir de bases populares, com forte presença de trabalhadores rurais sem-terra, moradores de áreas periféricas
                 e ativistas em defesa de direitos sociais.
            </p>
            <p>
                Desde a sua criação, o MNL tem promovido atos públicos, ocupações de terrenos urbanos e rurais, e dialogado com
                diferentes esferas do poder público para reivindicar uma agenda que priorize o acesso à moradia e à terra como
                elementos essenciais para a construção de uma sociedade mais justa. O movimento também busca articular-se com
                outras organizações e movimentos sociais, ampliando a luta por políticas mais inclusivas e transformadoras.
            </p>
            <p>
            Ricardo Terra, o idealizador do movimento, destaca que o MNL nasce da necessidade de mobilização conjunta da 
            sociedade para pressionar o Estado a cumprir seu papel na garantia de direitos básicos, além de incentivar a 
            autogestão de comunidades e assentamentos, promovendo soluções colaborativas e sustentáveis.
            </p>
        </div>
    )
}