import React, { useState, useContext } from 'react'
import { AuthContexts } from '../../Components/Contexts/AuthContext'
import { IMaskInput } from 'react-imask'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import apiLocal from '../../apiLocal/apiLocal'

import './loginCoordenadores.estilo.scss'

export default function LoginCoordenadores() {

    const { loginCoordenador } = useContext(AuthContexts)

    const [cpfMask, setCpfMask] = useState('')
    const [password, setPassword] = useState('')

    async function loginCord(e) {
        e.preventDefault()
        try {
            if (!cpfMask || !password) {
                toast.error('Preencha todos os campos', {
                    toastId: 'ToastID'
                })
                return
            }

            let cpf = cpfMask.match(/\d/g).join("")
            await loginCoordenador(cpf, password)

        } catch (err) {
            toast.error('Erro ao Conectar no Servidor', {
                toastId: 'ToastId'
            })

        }
    }
    return (
        <div className='conteinerLoginCoordenadoresGeral'>
            <h1>Login Coordenadores</h1>
            <form onSubmit={loginCord}>
                <IMaskInput
                    type='text'
                    mask='000.000.000-00'
                    placeholder='Seu CPF é o Login para entrar'
                    value={cpfMask}
                    onChange={(e) => setCpfMask(e.target.value)}
                />
                <input
                    type='password'
                    placeholder='Digite a Senha'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <button type='submit'>Enviar</button>
            </form>
        </div>
    )
}