import React, { useContext, useState, useEffect } from 'react'
import { AuthContexts } from '../../Components/Contexts/AuthContext'

import NAutorizado from '../../imagens/nautorizado.png'

export default function CoordFinanceiro() {

    const { verificaToken } = useContext(AuthContexts)
    verificaToken()

    const [coordenador, setCoordenador] = useState('')
    const [grupo, setGrupo] = useState('')

    useEffect(() => {
        try {
            async function buscaDados() {
                const cordT = localStorage.getItem('@Cord')
                const grupoT = localStorage.getItem('@GrupoNome')
                setCoordenador(JSON.parse(cordT))
                setGrupo(JSON.parse(grupoT))
            }
            buscaDados()
        } catch (err) {

        }
    }, [])

    return (
        <div>
            {coordenador === false || grupo !== 'Financeiro' ?
                <img src={NAutorizado} alt="" />
                :
                <h1>Dashboard Financeiro</h1>
            }
        </div>
    )
}