import React, { useContext, useState, useEffect } from 'react'
import { AuthContexts } from '../../../Components/Contexts/AuthContext'
import { useParams, useNavigate } from 'react-router-dom';
import apiLocal from '../../../apiLocal/apiLocal'
import './conferirDadosAcampado.estilo.scss'
import NAutorizado from '../../../imagens/nautorizado.png'

import { IMaskInput } from 'react-imask'

export default function ConferirAcampado() {

    const { verificaToken } = useContext(AuthContexts)
    verificaToken()

    const mudarTela = useNavigate()

    const [coordenador, setCoordenador] = useState('')
    const [grupo, setGrupo] = useState('')

    const { id } = useParams()
    const [dados, setDados] = useState('')

    const [numeroRancho, setSetNumeroRancho] = useState('')
    const [status, setStatus] = useState('')

    const iToken = localStorage.getItem('@Token')
    const token = JSON.parse(iToken)
   
    useEffect(() => {
        try {
            async function buscaDados() {
                const cordT = localStorage.getItem('@Cord')
                const grupoT = localStorage.getItem('@GrupoNome')
                setCoordenador(JSON.parse(cordT))
                setGrupo(JSON.parse(grupoT))
            }
            buscaDados()
        } catch (err) {

        }
    }, [])

    useEffect(() => {
        try {
            async function listarAcampados() {
                const resposta = await apiLocal.post('/ListarAcampadosAtivar', {
                    id
                }, {
                    headers: {
                        Authorization: 'Bearer ' + `${token}`
                    }
                })
                setDados(resposta.data)
            }
            listarAcampados()
        } catch (err) {

        }
    }, [])

    async function ativarAcampado(e){
        e.preventDefault()
       try {
        apiLocal.put('/AtivarAcampado', {
            id,
            numeroRancho,
            status
        }, {
            headers: {
                Authorization: 'Bearer ' + `${token}`
            }
        })
        mudarTela('/AtivarAcampado')
       } catch (err) {
        
       }
        
    }
   
    return (
        <div>
            <div className='containerGeralConferiDadosAcampados'>
                {coordenador === false || grupo !== 'ADM' && grupo !== 'ROOT' ?
                    <img src={NAutorizado} alt="" />
                    :
                    <div>
                        <div className='tituloConferirdadosAcampados'>
                            <h1>Ativação de Acampados</h1>
                        </div>
                        <div className='blocosAtivarDadosAcampados'>
                            <div className='blocosTitular'>
                                <h1>Titular</h1>
                                <span>Nome: {dados.nome}</span>
                                <span>
                                    CPF: <IMaskInput
                                        disabled
                                        type="text"
                                        mask='000.000.000-00'
                                        value={dados.cpf} />
                                </span>
                                <span>RG: {dados.rg}</span>
                                <span>NIS: {dados.nis}</span>
                                <span>Cad. Único: {dados.cadunico}</span>
                                <span>Titulo Eleitor: {dados.tituloeleitor}</span>
                                <span>
                                    Celular: <IMaskInput
                                        disabled
                                        type="text"
                                        mask='(00) 00000-0000'
                                        value={dados.celular}
                                    />
                                </span>
                            </div>
                            {dados.conjuge != null && (
                                <div className='blocosTitular'>
                                    <h1>Conjuge</h1>
                                    <span>Nome: {dados.conjuge.nome}</span>
                                    <span>
                                        CPF: <IMaskInput
                                            disabled
                                            type="text"
                                            mask='000.000.000-00'
                                            value={dados.conjuge.cpf} />
                                    </span>
                                    <span>RG: {dados.conjuge.rg}</span>
                                    <span>NIS: {dados.conjuge.nis}</span>
                                    <span>Cad. Único: {dados.cadunico}</span>
                                    <span>Titulo Eleitor: {dados.conjuge.tituloeleitor}</span>
                                    <span>
                                        Celular: <IMaskInput
                                            disabled
                                            type="text"
                                            mask='(00) 00000-0000'
                                            value={dados.conjuge.celular}
                                        />
                                    </span>
                                </div>
                            )}
                            {dados.filhosMenores > 0 && (
                                <div className='blocosTitular'>
                                    <h1>Filhos</h1>
                                </div>
                            )}
                        </div>
                    </div>
                }
                <form onSubmit={ativarAcampado}>
                    <input
                        type="number"
                        placeholder='Digite o Numero do Rancho'
                        value={numeroRancho}
                        onChange={(e) => setSetNumeroRancho(e.target.value)}
                    />
                    <select 
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                    >
                        <option></option>
                        <option>Ativo</option>
                        <option>Inativo</option>
                    </select>
                    <button>Enviar</button>
                    <button className='buttonSair' onClick={() => mudarTela('/AtivarAcampado')}>Sair</button>
                </form>
            </div>
        </div>
    )
}