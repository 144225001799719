import { createContext, useState } from 'react'
import apiLocal from '../../apiLocal/apiLocal'
import { toast } from 'react-toastify'

export const AuthContexts = createContext()

export default function AuthProvider({ children }) {

    const [tokenT, setTokenT] = useState(false)
    const [token, setToken] = useState('')

    const autenticado = !!tokenT

    async function verificaToken() {
        const iToken = localStorage.getItem('@Token')
        if (!iToken) {
            setToken(false)
            return
        }
        const tokenU = JSON.parse(iToken)
        setToken(tokenU)
        try {
            const resposta = await apiLocal.get('/ListaUsuariosToken', {
                headers: {
                    // eslint-disable-next-line
                    Authorization: 'Bearer ' + `${token}`
                }
            })
            if (resposta.data.id) {
                setTokenT(true)
                localStorage.setItem('@Rancho', JSON.stringify(resposta.data.numeroRancho))
                localStorage.setItem('@Status', JSON.stringify(resposta.data.status))
                localStorage.setItem('@Pontuacao', JSON.stringify(resposta.data.pontuacao))
                localStorage.setItem('@Cord', JSON.stringify(resposta.data.coordenador))
                localStorage.setItem('@GrupoNome', JSON.stringify(resposta.data.grupos.nome))
                localStorage.setItem('@GrupoId', JSON.stringify(resposta.data.grupoCordId))
            } else {
                setTokenT(false)
            }
        } catch (err) {
            toast.error('Token Inválido', {
                toastId: 'toastId'
            })
        }
    }

    async function loginAcampados(cpf, password) {
        try {
            const resposta = await apiLocal.post('/LoginAcampados', {
                cpf, password
            })
            localStorage.setItem('@Id', JSON.stringify(resposta.data.id))
            localStorage.setItem('@Nome', JSON.stringify(resposta.data.nome))
            localStorage.setItem('@Pontuacao', JSON.stringify(resposta.data.pontuacao))
            localStorage.setItem('@Cord', JSON.stringify(resposta.data.coordenador))
            localStorage.setItem('@GrupoNome', JSON.stringify(resposta.data.nomeGrupo))
            localStorage.setItem('@GrupoId', JSON.stringify(resposta.data.grupoCordId))
            localStorage.setItem('@Token', JSON.stringify(resposta.data.token))
            setTokenT(true)
            toast.success('Login Efetuado Com Sucesso', {
                toastId: 'ToastID'
            })
        } catch (err) {
            /*toast.error(err.response.data.error, {
                toastId: 'ToastID'
            })*/
           console.log(err)
        }
    }

    /*async function loginCoordenador(cpf, password) {
        try {
            const resposta = await apiLocal.post('/LoginCoordenadores', {
                cpf, password
            })
            localStorage.setItem('@Cord', JSON.stringify(resposta.data.coordenador))
            localStorage.setItem('@Id', JSON.stringify(resposta.data.id))
            localStorage.setItem('@Nome', JSON.stringify(resposta.data.nome))
            localStorage.setItem('@GrupoNome', JSON.stringify(resposta.data.grupoNome))
            localStorage.setItem('@GrupoId', JSON.stringify(resposta.data.grupoCordId))
            localStorage.setItem('@Token', JSON.stringify(resposta.data.token))
            setTokenT(true)
            toast.success('Login Efetuado Com Sucesso', {
                toastId: 'ToastID'
            })
        } catch (err) {
            toast.error(err.response.data.error, {
                toastId: 'ToastID'
            })
        }
    }*/

    return (
        <AuthContexts.Provider value={({ loginAcampados, autenticado, verificaToken })}>
            {children}
        </AuthContexts.Provider>
    )
}