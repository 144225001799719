import React, { useContext, useState, useEffect } from 'react'
import { AuthContexts } from '../../../Components/Contexts/AuthContext'
import { Link } from 'react-router-dom'
import apiLocal from '../../../apiLocal/apiLocal'
import './ativarDadosAcampado.estilo.scss'
import NAutorizado from '../../../imagens/nautorizado.png'

import { IMaskInput } from 'react-imask'


export default function AtivarAcampado() {

    const { verificaToken } = useContext(AuthContexts)
    verificaToken()

    const [acamapdosPendente, setAcampadosPendente] = useState([''])

    const [coordenador, setCoordenador] = useState('')
    const [grupo, setGrupo] = useState('')

    const iToken = localStorage.getItem('@Token')
    const token = JSON.parse(iToken)

    useEffect(() => {
        try {
            async function buscaDados() {
                const cordT = localStorage.getItem('@Cord')
                const grupoT = localStorage.getItem('@GrupoNome')
                setCoordenador(JSON.parse(cordT))
                setGrupo(JSON.parse(grupoT))
            }
            buscaDados()
        } catch (err) {

        }
    }, [])


    useEffect(() => {
        try {
            async function listarAcampados() {
                const resposta = await apiLocal.get('/ListarAcampadosPendente', {
                    headers: {
                        Authorization: 'Bearer ' + `${token}`
                    }
                })
                setAcampadosPendente(resposta.data)
            }
            listarAcampados()
        } catch (err) {

        }
    }, [acamapdosPendente])

    return (
        <div>
            <div className='containerGeralAtivarDadosAcampados'>
                {coordenador === false || grupo !== 'ADM' && grupo !== 'ROOT' ?
                    <img src={NAutorizado} alt="" />
                    :
                    <div>
                        <div className='tituloAtivardadosAcampados'>
                            <h1>Ativação de Acampados</h1>
                        </div>
                        <div className='tabelaAtivarDadosAcampados'>
                            <table className='dadosTabelas'>
                                <thead>
                                    <tr key="">
                                        <th>Nome</th>
                                        <th>CPF</th>
                                        <th>Situação</th>
                                        <th>Ativar</th>
                                    </tr>
                                    {acamapdosPendente.map((item) => {
                                        return (
                                            <tr key={item.id}>
                                                <>
                                                    <td>{item.nome}</td>
                                                    <td>
                                                        <IMaskInput
                                                            disabled
                                                            mask='000.000.000-00'
                                                            value={item.cpf}
                                                        />
                                                    </td>
                                                    <td>{item.status}</td>
                                                    <td><Link to={`/ConferirAcampado/${item.id}`}>Ativar</Link></td>
                                                </>
                                            </tr>
                                        )
                                    })}
                                </thead>
                            </table>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}