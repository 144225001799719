import React, { useContext, useState, useEffect } from 'react'
import { AuthContexts } from '../Components/Contexts/AuthContext'
import { Link } from 'react-router-dom'
import apiLocal from '../apiLocal/apiLocal'

import './dashboardAcampado.estilo.scss'
import { toast } from 'react-toastify'


export default function Dashboard() {

    const { verificaToken } = useContext(AuthContexts)
    verificaToken()

    const [nome, setNome] = useState('')
    const [status, setStatus] = useState('')
    const [rancho, setRancho] = useState('')
    const [pontuacao, setPontuacao] = useState('')

    const [conjugeExiste, setConjugeExiste] = useState(false)
    const [conjuge, setConjuge] = useState('')

    const iToken = localStorage.getItem('@Token')
    const token = JSON.parse(iToken)

    useEffect(() => {
        try {
            async function buscaDados() {
                const nomeT = localStorage.getItem('@Nome')
                const statusT = localStorage.getItem('@Status')
                const ranchoT = localStorage.getItem('@Rancho')
                const pontuacaoT = localStorage.getItem('@Pontuacao')
                const grupoNomeT = localStorage.getItem('@GrupoNome')
                setNome(JSON.parse(nomeT))
                setStatus(JSON.parse(statusT))
                setRancho(JSON.parse(ranchoT))
                setPontuacao(JSON.parse(pontuacaoT))
            }
            buscaDados()
        } catch (err) {
            toast.error('Erro ao comunicar com o Servidor')
        }
    }, [])

    useEffect(() => {
        try {
            async function buscaConjuge() {
                const resposta = await apiLocal.get('/VerDadosAcampadosConjuge', {
                    headers: {
                        Authorization: 'Bearer ' + `${token}`
                    }
                })
                setConjuge(resposta.data)
                if (resposta.data === null) {
                    setConjugeExiste(false)
                } else {
                    setConjugeExiste(true)
                }

            }
            buscaConjuge()
        } catch (err) {
        }

    }, [])

    return (
        <div>
            <div className='conteinerGeralDasboardAcampado'>
                <div className='tituloDashboardAcampado'>
                    <h1>Dashboard Acampados</h1>                   
                </div>
                <div className='dadosDashboardAcampados'>
                    <h2>Dados dos Acampados</h2>
                    <table className='dadosTabelas'>
                        <thead>
                            <tr key="">
                                <th>Nome do Acampado</th>
                                <th>Situação do Acampado</th>
                                <th>Numero do Rancho do Acampado</th>
                                <th>Pontuação</th>
                            </tr>
                            <tr key="">
                                <td>{nome.toLocaleUpperCase()}</td>
                                {status === 'Ativo' && (
                                    <td className='corStatusAcampadoAtivo'>{status}</td>
                                )}
                                {status === 'Pendente' && (
                                    <td className='corStatusAcampadoPendente'>{status}</td>
                                )}
                                {status === 'Inativo' && (
                                    <td className='corStatusAcampadoInativo'>{status}</td>
                                )}
                                {rancho === null ? <td>Não Informado</td> : <td>{rancho}</td>}
                                <td>{pontuacao}</td>
                            </tr>
                        </thead>
                    </table>
                </div>
                <div className='dadosDashboardAcampados'>
                    <h2>Dados Conjuge</h2>
                    {conjugeExiste === false && (
                        <Link to='/CadastroAcampadosConjuge'>Cadastro Conjuge</Link>
                    )}
                    <table className='dadosTabelas'>
                        <thead>
                            <tr key="">
                                <th>Nome</th>
                            </tr>
                            <tr key="">
                                {conjugeExiste === false ? <td></td> : <td>{conjuge.nome.toLocaleUpperCase()}</td>}
                            </tr>
                        </thead>
                    </table>
                </div>
                <div className='dadosDashboardAcampados'>
                    <h2>Dados Filhos</h2>
                    <Link to='/'>Cadastro Filhos</Link>
                    <table className='dadosTabelas'>
                        <thead>
                            <tr key="">
                                <th>Nome</th>
                            </tr>
                            <tr key="">

                            </tr>
                        </thead>
                    </table>
                </div>
                <div className='dadosDashboardAcampados'>
                    <h2>Doações em Dinheiro</h2>
                    <table className='dadosTabelas'>
                        <thead>
                            <tr key="">
                                <th>Data Doação</th>
                                <th>Valor Doado</th>
                                <th>Situação Doação</th>
                            </tr>
                            <tr key="">

                            </tr>
                        </thead>
                    </table>
                </div>
                <div className='dadosDashboardAcampados'>
                    <h2>Doações em Alimento</h2>
                    <table className='dadosTabelas'>
                        <thead>
                            <tr key="">
                                <th>Data Doação</th>
                                <th>Alimento Doado</th>
                                <th>Situação Doação</th>
                            </tr>
                            <tr key="">

                            </tr>
                        </thead>
                    </table>
                </div>
                <div className='dadosDashboardAcampados'>
                    <h2>Doações em Roupas</h2>
                    <table className='dadosTabelas'>
                        <thead>
                            <tr key="">
                                <th>Data Doação</th>
                                <th>Roupa Doada</th>
                                <th>Situação Doação</th>
                            </tr>
                            <tr key="">

                            </tr>
                        </thead>
                    </table>
                </div>
                <div className='dadosDashboardAcampados'>
                    <h2>Trabalhos no Acampamento</h2>
                    <table className='dadosTabelas'>
                        <thead>
                            <tr key="">
                                <th>Data Trabalho</th>
                                <th>Tipo Trabalho</th>
                                <th>Situação Trabalho</th>
                            </tr>
                            <tr key="">

                            </tr>
                        </thead>
                    </table>
                </div>
            </div>
        </div>
    )
}