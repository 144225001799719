import React, { useContext, useState, useEffect } from 'react'
import { AuthContexts } from '../Components/Contexts/AuthContext'
import { Link } from 'react-router-dom'
import './dashboardCoordenadores.estilo.scss'

import Adm from '../imagens/adm.png'
import Financeiro from '../imagens/financeiro.png'


export default function DashboardCoordenadores() {

    const { verificaToken } = useContext(AuthContexts)
    verificaToken()

    const [coordenador, setCoordenador] = useState('')

    useEffect(() => {
        try {
            async function buscaDados() {
                const cordT = localStorage.getItem('@Cord')
                setCoordenador(JSON.parse(cordT))
            }
            buscaDados()
        } catch (err) {

        }
    }, [])


    return (
        <div>
            <div className='conteinerGeralDasboardCoordenadores'>
                {coordenador === false ?
                    <>
                        <div className='tituloDashboardCoordenador'>
                            <h1>Acesso Não Autorizado</h1>
                        </div>
                    </>
                    :
                    <>
                        <div className='tituloDashboardCoordenador'>
                            <h1>DashBoard Coordenadores</h1>
                        </div>
                        <div className='corpoDashboardCoordenadores'>
                            <Link to='/CoordAdministracao'><img src={Adm} alt="ADM" /></Link>
                            <Link to='/CoordFinanceiro'><img src={Financeiro} alt="Financeiro" /></Link>
                        </div>
                    </>
                }
            </div>
        </div>
    )
}