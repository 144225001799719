import { BrowserRouter, Routes, Route } from 'react-router-dom'

import FaixaHeader from '../Components/FaixaHeader'
import Cabecalho from '../Components/Cabecalho'
import Rodape from '../Components/Rodape'

import Dashboard from '../Dashboard'
import DashboardCoordenadores from '../DashboardsCoordenadores'

import CadastroAcampadosConjuge from '../CadastroAcampadosConjuge'

import CoordAdministracao from '../DashboardsCoordenadores/Administracao'
import CoordFinanceiro from '../DashboardsCoordenadores/Financeiro'
import AtivarAcampado from '../DashboardsCoordenadores/Administracao/AtivarAcampado'
import ConferirAcampado from '../DashboardsCoordenadores/Administracao/ConferirAcampado'

export default function Autenticado() {
    return (
        <BrowserRouter>
            <FaixaHeader />
            <Cabecalho />
            <Routes>
                <Route path='/' element={<Dashboard />} />
                <Route path='/DashboardCoordenadores' element={<DashboardCoordenadores />} />
                <Route path='/CadastroAcampadosConjuge' element={<CadastroAcampadosConjuge />} />
                <Route path='/CoordAdministracao' element={<CoordAdministracao />} />
                <Route path='/CoordFinanceiro' element={<CoordFinanceiro />} />
                <Route path='/AtivarAcampado' element={<AtivarAcampado />} />
                <Route path='/ConferirAcampado/:id' element={<ConferirAcampado />} />

                <Route path='*' element={<Dashboard />} />
            </Routes>
            <Rodape />
        </BrowserRouter>
    )
}