import React from 'react'
import './faixaHeader.estilo.scss'

import NomeMNL from '../../imagens/nome.png'
import Bandeira from '../../imagens/BANDEIRA_MNL.png'
import Facebook from '../../imagens/face.png'
import Instagram from '../../imagens/insta.png'
import WhatsApp from '../../imagens/whats.png'


export default function FaixaHeader() {
    return (
        <div className='conteinerFaixaHeaderGeral'>
            <div className='faixaLadoEsquerdo'>
                <img src={Bandeira} alt='Bandeira MNL' />
            </div>
            <div className='faixaLadoCentro'>
                <img src={NomeMNL} alt='Nome MNL' />
            </div>
            <div className='faixaLadoDireita'>
                <img src={Facebook} alt='Facebook' />
                <img src={Instagram} alt='Instagram' />
                <img src={WhatsApp} alt='WhatsApp' />
            </div>
        </div>
    )
}