import React, { useContext, useState, useEffect } from 'react'
import { AuthContexts } from '../../Components/Contexts/AuthContext'
import { Link } from 'react-router-dom';
import NAutorizado from '../../imagens/nautorizado.png'
import './estilo.coordenadoresAdministracao.scss'

export default function CoordAdministracao() {

    const { verificaToken } = useContext(AuthContexts)
    verificaToken()

    const [coordenador, setCoordenador] = useState('')
    const [grupo, setGrupo] = useState('')

    useEffect(() => {
        try {
            async function buscaDados() {
                const cordT = localStorage.getItem('@Cord')
                const grupoT = localStorage.getItem('@GrupoNome')
                setCoordenador(JSON.parse(cordT))
                setGrupo(JSON.parse(grupoT))
            }
            buscaDados()
        } catch (err) {

        }
    }, [])

    return (
        <div>
            <div className='containerCoordenadorAdministracaoGeral'>
                {coordenador === false || grupo !== 'ADM' && grupo !== 'ROOT' ?
                    <img src={NAutorizado} alt="" />
                    :
                    <div>
                        <div className='tituloCoordenadorAdministracao'>
                            <h1>DashBoard Administração</h1>
                        </div>
                        <div className='acessosAcampadosCoordenadorAdministracao'>
                            <div className='acessosAcampadosCoordenadorAdministracaoInt1'>
                                <Link to='/AtivarAcampado'>Ativar Acampado</Link>
                                <Link>Listar Acampados</Link>
                            </div>
                            <div className='acessosAcampadosCoordenadorAdministracaoInt2'>
                                <Link>Pesquisa Acampado</Link>
                                <Link>Editar Acampado</Link>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}