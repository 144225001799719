import React, { useContext } from 'react'
import { AuthContexts } from '../Components/Contexts/AuthContext'

import Autenticado from './autenticado.routes'
import NaoAutenticado from './naoAutenticado.routes'

export default function Rotas() {

    const { autenticado } = useContext(AuthContexts)
    return (
        autenticado === true ? <Autenticado /> : <NaoAutenticado />
    )
}