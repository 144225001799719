import React, { useContext } from 'react'
import { AuthContexts } from '../Contexts/AuthContext'
import { useNavigate } from 'react-router-dom'

import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import './cabecalho.estilo.scss'



export default function Cabecalho() {

    const { autenticado } = useContext(AuthContexts)
    const navegar = useNavigate()

    const coordenadorT = localStorage.getItem('@Cord')
    const coordenador = JSON.parse(coordenadorT) 

    async function handleSair() {
        localStorage.clear('')
        navegar('/')
        window.location.reload()
    }
    return (
        <div className='conteinerCabecalhoGeral'>
            <Navbar expand='lg'>
                <Container>
                    <Navbar.Toggle aria-controls='basic-navbar-nav' />
                    <Navbar.Collapse id='basic-navbar-nav'>
                        <Nav className='me-auto'>
                            {autenticado === false && (
                                <>
                                    <Nav.Link href='/'>Início</Nav.Link>
                                    <Nav.Link href='/QSomos'>Quem Somos</Nav.Link>
                                    <Nav.Link href='/'>Projetos</Nav.Link>
                                    <Nav.Link href='/'>Encontros</Nav.Link>
                                    <Nav.Link href='/'>Noticias</Nav.Link>
                                    <Nav.Link href='/'>Contatos</Nav.Link>
                                    <Nav.Link href='/Login'>Acessos</Nav.Link>
                                </>
                            )}
                            {autenticado === true && (
                                <>                                   
                                    {coordenador === true && (
                                        <Nav.Link href='/DashboardCoordenadores'>Coordenação</Nav.Link>
                                    )}
                                    <Nav.Link href='/'>Doação Dinheiro</Nav.Link>
                                    <Nav.Link href='/'>Doação Alimentos</Nav.Link>
                                    <Nav.Link href='/'>Doação Roupa</Nav.Link>
                                    <button onClick={handleSair}>Sair</button>
                                </>
                            )}
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

        </div>
    )
}